<template>
<div class="ticket-container">
    <div class="ticket-header">
        <img src="./../../../assets/images/details/ticket1.png" alt="">
        <div class="title">推荐票</div>
        <div class="get_methods" @click="goGuide">如何获得推荐票？</div>
    </div>
    <div class="ticket-content">
        <img src="./../../../assets/images/details/ticket2.png" alt="">
        <div class="ticket_btns" v-if="!getLoginStatus">
            <el-button type="primary" @click="throwAction(true)" size="small">登录</el-button>
            <div class="have">投票鼓励作者吧～</div>
        </div>
        <div class="ticket_btns" v-else>
            <el-button type="primary" @click="throwAction()" size="small">推荐票</el-button>
            <div class="have">拥有：<span>{{ $store.state.money_info.month_ticket_num }}</span></div>
        </div>
    </div>

    <el-dialog
            title="投推荐票"
            :append-to-body="true"
            :visible.sync="ticketModal"
            class="ticket-modal"
            width="234px">
        <div class="content">
            <el-input-number size="small" v-model="ticketNum" :precision="0" :min="1" label="推荐票数量"></el-input-number>
            <div class="have">我的票数:<span>{{ $store.state.money_info.month_ticket_num }}</span></div>
        </div>
        <div class="btns">
            <el-button type="primary" size="small" @click="sureAction">确定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
    import {throw_ticket} from "@/https/read_books.js";
    export default {
        name: "throw_ticket",
        data(){
            return {
                ticketModal: false,
                ticketNum: 1
            }
        },
        computed: {
            getLoginStatus(){
                return this.had_login();
            },
            getDetail(){
                return this.$store.state.book_info;
            },
            getMoneyInfo(){
                return this.$store.state.money_info;
            },
        },
        methods: {
            throwAction(noTips){
                if(this.had_login()){
                    this.ticketModal = true;
                }else{
                    if(!noTips) this.$message.warning("登录后才能投票哦～");
                    this.$store.commit("set_login_type", true);
                }
            },
            sureAction(){
                let params = {
                    num: this.ticketNum,
                    book_id: this.getDetail.id || this.$route.query.id
                }
                throw_ticket(params).then(res=>{
                    if(res.code === 0){
                        this.$message.success('成功投送推荐票！');
                        this.$store.dispatch('updateMoneyinfo');
                        this.$store.state.messageVue.$emit('costAction');
                        this.ticketModal = false;
                    }
                })
            },
            goGuide(){
                this.$router.push({
                    path: "/guid",
                    query: {
                        id: 'guid-ticket',
                    },
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ticket-container{
        margin-top: 15px;
        padding: 15px 20px;
        background-color: #FFF;
        border-radius: 5px;
        .ticket-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            img{
                width: 25px;
            }
            .title{
                margin: 0 10px;
                margin-left: 17px;
                flex-grow: 2;
                font-weight: bold;
            }
            .get_methods{
                color: #409EFF;
                cursor: pointer;
            }
        }
        .ticket-content{
            display: flex;
            padding: 18px 0;
            align-items: center;
            justify-content: space-around;
            &>img{
                width: 56px;
            }
            .ticket_btns{
                position: relative;
                text-align: center;
                .have{
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    margin-top: 12px;
                    transform: translate(-50%, 100%);
                    color: #999999;
                    font-size: 12px;
                    line-height: 2em;
                    white-space: nowrap;
                    span{
                        color: #E03642;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .ticket-modal{
        .el-dialog__body{
            padding: 15px;
        }
        .content{
            padding: 0 15px 15px;
            text-align: center;
        }
        .btns{
            margin-top: 10px;
            text-align: center;
        }
        .el-input__inner{
            height: 32px!important;
        }
        .have{
            margin-top: 12px;
            color: #999999;
            font-size: 12px;
            line-height: 2em;
            white-space: nowrap;
            span{
                color: #E03642;
            }
        }
    }
</style>
