<template>
    <div class="gift-container">
        <div class="gift-header">
            <img src="./../../../assets/images/details/magic.png" alt="">
            <div class="title">打赏道具</div>
        </div>
        <div class="gift-content-con">
            <img src="./../../../assets/images/details/right2.png" @click="gitftPrev" alt="" class="icon icon-left">
            <img src="./../../../assets/images/details/right2.png" @click="giftNext" alt="" class="icon icon-right">
            <div class="gift-content">
                <div class="gift-list-container" :style="'margin-left:' + getLeftDistance">
                    <div class="item" v-for="(item, index) in giftList" :key="''+item.id+selected.id+index">
                        <div class="img-con" @click="selectGiftAction(item)">
                            <img :src="item.icon" alt="">
                        </div>
                        <el-checkbox :checked="selected.id === item.id" @change="checkChange($event, item)">{{ item.name }}</el-checkbox>
                        <div class="gift-price" @click="selectGiftAction(item.id)">价值:{{ item.price }}鲸币</div>
                    </div>
                </div>
                <div class="gift-btns" v-if="getLoginStatus">
                    <el-button type="primary" size="small" @click="throwAction()">确定打赏</el-button>
                    <div class="have">拥有:<span>{{ getMoneyInfo.book_coin }}</span>鲸币</div>
                </div>
                <div class="gift-btns" v-else>
                    <el-button type="primary" size="small" @click="throwAction(true)">登录</el-button>
                    <div class="have">登录后可打赏作者</div>
                </div>
            </div>
        </div>

        <el-dialog
            title="道具打赏"
            v-if="giftModal"
            :append-to-body="true"
            :visible.sync="giftModal"
            class="gift-modal"
            width="340px">
            <div class="content">
                <div class="gift-show">
                    <img :src="selected.icon" alt="">
                    <div inline class="name">{{ selected.name }}</div>
                </div>
                <div class="key key1">
                    打赏数量：<el-input-number v-model="rewardNum" size="small" :precision="0" :min="1" label="推荐票数量"></el-input-number>
                </div><br />
                <div class="key">
                    购买所需鲸币：{{ getNeedCoin }}
                </div><br />
                <div class="key">
                    我的鲸币余额：{{ getMoneyInfo.book_coin }}
                </div>
            </div>
            <div class="btns">
                <el-button type="primary" size="small" @click="sureAction">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {get_gift_list, giveGift} from "@/https/read_books.js";
export default {
    name: "throw_ticket",
    data(){
        return {
            giftModal: false,
            rewardNum: 1,
            giftList: [],
            showIndex: 0, //左右切换时最左侧显示的索引
            selected: {} //选中的道具列表
        }
    },
    mounted() {
        this.getGiftList();
    },
    computed: {
        getLoginStatus(){
            return this.had_login();
        },
        getDetail(){
            return this.$store.state.book_info;
        },
        getMoneyInfo(){
            return this.$store.state.money_info;
        },
        getNeedCoin(){
            return this.rewardNum * this.selected.price || 0;
        },
        getLeftDistance(){
            return this.showIndex * -130 + 'px'
        }
    },
    methods: {
        getGiftList(){
            get_gift_list().then(res=>{
                if(res.code === 0){
                    this.giftList = res.data;
                }
            })
        },
        gitftPrev(){
            if(this.showIndex - 2 >= 0){
                this.showIndex -= 2;
            }else if(this.showIndex -1 >= 0){
                this.showIndex -= 1;
            }
        },
        giftNext(){
            let length = this.giftList.length;
            if(this.showIndex + 2 < length - 1){
                this.showIndex += 2;
            }else if(this.showIndex + 2 < length){
                this.showIndex += 1;
            }
        },
        throwAction(noTips){
            if(this.had_login()){
                if(this.selected.id){
                    this.giftModal = true;
                }else{
                    this.$message.warning('请先选择打赏道具');
                }
            }else{
                if(!noTips) this.$message.warning("登录后才能投票哦～");
                this.$store.commit("set_login_type", true);
            }
        },
        sureAction(){
            giveGift({
                prop_id: this.selected.id,
                book_id: this.getDetail.id || this.$route.query.id,
                num: this.rewardNum
            }).then(res=>{
                if(res.code === 0){
                    this.$message.success('打赏成功');
                    this.$store.dispatch('updateMoneyinfo');
                    this.$store.state.messageVue.$emit('costAction');
                    this.giftModal = false;
                }
            })
        },
        selectGiftAction(item){
            if(this.selected.id === item.id){
                this.selected = {};
            }else{
                this.selected = item;
            }
        },
        checkChange(checked, item){
            if(checked){
                this.selected = item;
            }else{
                this.selected = {};
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.gift-container{
    min-width: 300px;
    margin-top: 15px;
    padding: 15px 20px;
    background-color: #FFF;
    border-radius: 5px;
    user-select: none;
    .gift-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 25px;
        }
        .title{
            margin: 0 10px;
            margin-left: 17px;
            flex-grow: 2;
            font-weight: bold;
        }
        .get_methods{
            color: #409EFF;
            cursor: pointer;
        }
    }
    .gift-content-con{
        padding: 18px 0;
        position: relative;
        .icon{
            position: absolute;
            top: 50%;
            height: 16px;
            margin-top: -36px;
            cursor: pointer;
            transform: translateY(-50%);
            &.icon-left{
                left: -10px;
                margin-top: -44px;
                transform: rotateZ(-180deg);
            }
            &.icon-right{
                right: -10px;
            }
        }
    }
    .gift-content{
        overflow: hidden;
        .gift-list-container{
            display: inline-block;
            margin-bottom: 15px;
            margin-left: 0;
            white-space: nowrap;
            transition: 500ms linear;
            .item{
                display: inline-block;
                width: 130px;
                max-width: 130px;
                padding: 0 8px;
                cursor: pointer;
                text-align: center;
                .img-con{
                    display: flex;
                    width: 100%;
                    height: 70px;
                    align-items: center;
                    justify-content: center;
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .gift-price{
                    color: #999999;
                }
            }
        }
        .gift-btns{
            position: relative;
            text-align: center;
            .have{
                margin-top: 4px;
                color: #999999;
                font-size: 12px;
                line-height: 2em;
                white-space: nowrap;
                span{
                    color: #E03642;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.gift-list-container .el-checkbox{
    margin: 3px 0;
}
.gift-modal{
    .el-dialog__body{
        padding: 15px;
    }
    .content{
        text-align: center;
        padding: 0 15px 15px;
    }
    .btns{
        margin-top: 10px;
        text-align: center;
    }
    .el-input__inner{
        height: 32px!important;
    }
    .key{
        display: inline-block;
        text-align: left;
        min-width: 250px;
        line-height: 32px;
        &.key1{
            margin: 12px 0;
        }
    }
    .gift-show{
        img{
            display: block;
            max-width: 83px;
            max-height: 94px;
            margin: 0 auto;
        }
        .name{
            color: #333333;
            line-height: 3em;
        }
    }
}
</style>
